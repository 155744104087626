import * as React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import {
    AddAssociation,
    GetAssociationById,
    UpdateAssociation,
} from "../../../Redux/action";
import { saveAs } from "file-saver";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const menuItemsOfType = [
    "Membership",
    "Client",
    "Alliance",
    "Emapnelment",
    "Education tie-ups",
];

const AssociationForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { associationById } = useSelector((store) => store.AssociationReducer);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [imagePreview, setImagePreview] = useState("");
    const [fileInputs, setFileInputs] = useState({
        file: null,
    });

    const payloadObj = {
        Name: "",
        Description: "",
        Link: "",
        Type: "",
        User_role: "admin",
    };

    const [payload, setPayload] = useState(
        idFromLocation
            ? {
                ...associationById,
                Logo: associationById?.Logo || [],
                file: associationById?.file || [],
            }
            : payloadObj
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filesValue = e.target.files?.[0];
        if (name === "file") {
            const temp = URL.createObjectURL(filesValue);
            if (temp) {
                setImagePreview(temp);
            }
            setFileInputs({ ...fileInputs, [name]: filesValue });
        } else {
            setPayload({ ...payload, [name]: value });
        }
    };

    const handleResponseAddAssociation = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Association added successfully.`, {
                variant: "success",
            });
            navigate("/associations");
        } else {
            enqueueSnackbar(
                `${res?.data?.message || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleResponseUpdateAssociation = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Association updated successfully.`, {
                variant: "success",
            });
            navigate("/associations");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (key === "Logo") {
                var stringifiedImg = JSON.stringify(value);
                formData.append(key, stringifiedImg);
            } else {
                formData.append(key, value);
            }
        });

        Object.entries(fileInputs).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (type === "add") {
            dispatch(AddAssociation(formData, handleResponseAddAssociation));
        } else {
            dispatch(
                UpdateAssociation(
                    idFromLocation,
                    formData,
                    handleResponseUpdateAssociation
                )
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetAssociationById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation && associationById) {
            setPayload(associationById);
        }
    }, [associationById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("payload", payload);
    console.log("payload?.Type", payload?.Type);
    console.log("fileInputs", fileInputs);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Association Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        required
                        type="text"
                        label="Name"
                        name="Name"
                        value={payload?.Name || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    <MDInput
                        required
                        type="text"
                        label="Link"
                        name="Link"
                        value={payload?.Link || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    <FormControl required fullWidth>
                        <InputLabel>Type of association</InputLabel>
                        <Select
                            style={{ height: "45px" }}
                            label="Type of association"
                            name="Type"
                            value={payload?.Type || ""}
                            onChange={handleChange}
                        >
                            {menuItemsOfType?.map((item, i) => (
                                <MenuItem key={i} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        gap={3}
                        fullWidth
                    >
                        <MDTypography
                            variant="h6"
                            sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                            Upload Logo (Preferred dimensions are 1800 x 1200)
                        </MDTypography>
                        <MDInput
                            type="file"
                            name="file"
                            margin="dense"
                            onChange={handleChange}
                        />
                        {imagePreview ? (
                            <img
                                src={`${imagePreview}`}
                                alt="Association images"
                                style={{
                                    width: "50%",
                                    aspectRatio: "3/2",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <MDTypography
                                variant="h6"
                                sx={{ fontSize: "14px", fontWeight: "400" }}
                            >
                                No latest image selected yet.
                            </MDTypography>
                        )}

                        {type === "edit" && payload?.Logo ? (
                            <ImageList
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    // marginTop: "2rem",
                                    padding: "5px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    "@media (max-width: 961px)": {
                                        justifyContent: "center",
                                    },
                                }}
                                rowHeight={164}
                            >
                                {type === "edit" && (
                                    <ImageListItem style={{ width: "200px" }}>
                                        <img
                                            src={`${docUrl}${payload?.Logo}`}
                                            alt={"Event Images"}
                                            style={{
                                                width: "100%",
                                                aspectRatio: "3/2",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </ImageListItem>
                                )}
                            </ImageList>
                        ) : null}
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add" ? "Add Association" : "Update Association"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default AssociationForm;
