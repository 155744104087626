import * as React from "react";
import {
    Grid,
    TextField,
    ImageList,
    ImageListItem,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AddSpecialProject, GetSpecialProjectById, UpdateSpecialProject } from "../../../Redux/action";
import DeleteIcon from "@mui/icons-material/Delete";

const SpecialProjectForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { specialProjectById } = useSelector((store) => store.SpecialProjectReducer);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [imagePreview, setImagePreview] = useState("");
    const [fileInputs, setFileInputs] = useState({
        file: null,
    });

    const payloadObj = {
        Title: "",
        Description: "",
        file: [],
        User_role: "admin"
    };

    const [payload, setPayload] = useState(
        idFromLocation
            ? {
                ...specialProjectById,
                file: specialProjectById?.file || [],
            }
            : payloadObj
    );
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "file" && files?.[0]) {
            const file = files[0];
            setImagePreview(URL.createObjectURL(file));
            setFileInputs({ ...fileInputs, file });
        } else {
            setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
        }
    };

    const handleRemoveImage = () => {
        setPayload({ ...payload, file: [] });
        setImagePreview("");
    };

    const handleResponseAddSpecialProject = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Special project added successfully.`, { variant: "success" });
            navigate("/special-project");
        } else {
            enqueueSnackbar(
                `${res?.data?.message || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleResponseUpdateSpecialProject = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Special project updated successfully.`, { variant: "success" });
            navigate("/special-project");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (key !== "file") {
                formData.append(key, value);
            }
        });

        if (fileInputs.file) {
            formData.append("file", fileInputs.file);
        }

        if (type === "add") {
            dispatch(AddSpecialProject(formData, handleResponseAddSpecialProject));
        } else {
            dispatch(
                UpdateSpecialProject(idFromLocation, formData, handleResponseUpdateSpecialProject)
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetSpecialProjectById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation && specialProjectById) {
            const updatedPayload = {
                ...specialProjectById,
                file:
                    typeof specialProjectById?.file === "string"
                        ? JSON.parse(specialProjectById?.file)
                        : specialProjectById?.file,
            };
            setPayload(updatedPayload);
        }
    }, [specialProjectById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
    }, [type]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Special Projects Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        required
                        type="text"
                        label="Title"
                        name="Title"
                        value={payload?.Title || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    <Grid container direction="column" gap={3} >
                        <Grid item xs={4}>
                            <MDTypography variant="h6" fontWeightLighter>
                                {type === "edit" ? "Update image" : "Add image"}
                                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                    {" "}
                                    (Preferred dimensions are 680 x 320)
                                </span>
                            </MDTypography>
                            <MDInput
                                type="file"
                                name="file"
                                margin="dense"
                                fullWidth
                                onChange={handleChange}
                            />
                            {imagePreview ? (
                                <img
                                    src={`${imagePreview}`}
                                    alt="Project images"
                                    style={{
                                        width: "50%",
                                        aspectRatio: "3/2",
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                <MDTypography variant="h6" sx={{ fontSize: "14px", fontWeight: "400" }}>
                                    No latest images selected yet.
                                </MDTypography>
                            )}

                            {payload?.file?.length > 0 && (
                                <ImageList
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginTop: "2rem",
                                        padding: "5px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                    rowHeight={164}
                                >
                                    {payload?.file.map((file, index) => (
                                        <ImageListItem key={index} style={{ width: "200px" }}>
                                            <img
                                                src={`${docUrl}${file}`}
                                                alt={"Project Images"}
                                                style={{
                                                    width: "100%",
                                                    aspectRatio: "3/2",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            <MDButton
                                                component="button"
                                                variant="body2"
                                                onClick={handleRemoveImage}
                                                fullWidth
                                                style={{ marginTop: "6px" }}
                                            >
                                                Remove the photo <DeleteIcon />
                                            </MDButton>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add" ? "Add Special Project" : "Update Special Project"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default SpecialProjectForm;
